class Accessibility {
    constructor() {
        this.user = new User();
        this.buttons = document.querySelectorAll('.main-application a[target="_blank"]');
        this.adjust_button = document.querySelector('.main-application #adjust_button');

        this.blank_page = document.querySelector('.main-application #blank_page');
        this.adjust_instructions = document.querySelector('.main-application #adjust_instructions');
        this.contrast_button = document.querySelector('.main-application #contrast_button');

        this.primary = this.blank_page.querySelector('.mdn-Modal-footer .mdn-Button--primary');
        this.secondary = this.blank_page.querySelector('.mdn-Modal-footer .mdn-Button--secondary');
        this.ok = this.adjust_instructions.querySelector('.mdn-Modal-footer .mdn-Button--secondary');


        this.libras_btn = document.querySelector('.mdn-Icon-libras');
        this.libras_btn.addEventListener("click", e => {
            if (!document.querySelector(".ht-skip")) {
                var ht = new HT({
                    token: "7980f66fc04e8a51e244928880939142"
                });
            }
        });
        this.primary.addEventListener("click", e => {
            window.open(this.primary.dataset.href, "_blank");
            this.blank_page.classList.remove("mdn-isOpen");
        });
        this.secondary.addEventListener("click", e => {
            this.blank_page.classList.remove("mdn-isOpen");
            this.primary.dataset.href = "";
        });
        this.contrast_button.addEventListener("click", e => {
            console.log(this);
            if (document.querySelector('html').classList.contains("mdn-Theme--contrast")) {
                window.MondrianVanilla.setTheme(``)
                this.user.data.accessibility.contrast = false
                this.user.update();
            } else {
                window.MondrianVanilla.setTheme(`contrast`)
                this.user.data.accessibility.contrast = true
                this.user.update();
            }
        });


        this.ok.addEventListener("click", e => {
            this.adjust_instructions.classList.remove("mdn-isOpen");
        });

        this.adjust_button.addEventListener("click", e => {
            this.adjust_instructions.classList.add("mdn-isOpen");
        });

        this.buttons.forEach(item => {
            item.addEventListener("click", e => {
                e.preventDefault();
                this.blank_page.classList.add("mdn-isOpen");
                this.primary.dataset.href = e.currentTarget.href;
            });
        });


        try {
            if (this.user.data.accessibility.contrast) {
                window.MondrianVanilla.setTheme(`contrast`)
            }
        } catch (error) {
            console.log(error);
        }
    }
}
window.addEventListener("eHelpFrameworkReady", e => {
    eHelpFramework.widgets.push(new Accessibility())
});