class Accessibility {
	constructor() {
		const _that = this;
		this.user = new User();

		this.blank_page = document.createElement('div');
		this.blank_page.innerHTML = `
		<div class="mdn-Modal-content mdn-Modal--sm">
			<div class="mdn-Modal-header">
			<h1 class="mdn-Heading mdn-Heading--xs ">${QelpCareHUB.translations.modal.attention}</h1>
			<button aria-label="Button close modal" class="mdn-Button mdn-Button--icon mdn-Button--secondary mdn-Button--md mdn-Button-header-close " title="close"> <em class="mdn-Icon-fechar mdn-Icon--sm "></em><span></span></button>
			</div>
			<div class="mdn-Modal-body">
			<div class="mdn-Modal-body--icon"><span class="mdn-Icon-circulo-exclamacao mdn-Icon--md"></span></div>
			<p class="mdn-Text mdn-Text--body ">${QelpCareHUB.translations.modal._blank}</p>
			</div>
			<div class="mdn-Modal-footer">
			<div class="mdn-Modal-footer-start"></div>
			<div class="mdn-Modal-footer-end">
			<button class="mdn-Button mdn-Button--secondary mdn-Button--md  "> <!-- -->${QelpCareHUB.translations.modal.cancel}</button>
			<button class="mdn-Button mdn-Button--primary mdn-Button--md  "> <!-- -->${QelpCareHUB.translations.modal.ok}</button>
			</div>
			</div>
		</div>`;
		// this.blank_page.innerHTML = `<div class="mdn-Modal-content"> <div class="mdn-Modal-header"> <h1 class="mdn-Heading mdn-Heading--md">${QelpCareHUB.translations.modal.attention} </h1> <button class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary" aria-label="Close modal"> <i class="mdn-Icon-fechar mdn-Icon--md"></i> </button> </div> <div class="mdn-Divider mdn-Divider--default"></div> <div class="mdn-Modal-body"> <p class="mdn-Text mdn-Text--body">${QelpCareHUB.translations.modal._blank} </p> </div> <div class="mdn-Modal-footer"> <div class="mdn-Modal-footer-end"> <button class="mdn-Button mdn-Button--secondary">${QelpCareHUB.translations.modal.cancel}</button> <button data-href="" class="mdn-Button mdn-Button--primary">${QelpCareHUB.translations.modal.confirm}</button> </div> </div> </div>`;
		this.blank_page_close = this.blank_page.querySelector(".mdn-Button-header-close");
		this.blank_page.id = "blank_page";
		this.blank_page.classList.add("mdn-Modal");

		this.adjust_instructions = document.createElement("div");
		this.adjust_instructions.innerHTML = `<div class="mdn-Modal-content"> <div class="mdn-Modal-header"> <h1 class="mdn-Heading mdn-Heading--md">${QelpCareHUB.translations.modal.attention} </h1> <button class="mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary" aria-label="Close modal"> <i class="mdn-Icon-fechar mdn-Icon--md"></i> </button> </div> <div class="mdn-Divider mdn-Divider--default"></div> <div class="mdn-Modal-body"> <p class="mdn-Text mdn-Text--body">${QelpCareHUB.translations.modal.adjust_instructions} </p> </div> <div class="mdn-Modal-footer"> <div class="mdn-Modal-footer-end"> <button class="mdn-Button mdn-Button--secondary">${QelpCareHUB.translations.modal.ok}</button> </div> </div> </div>`;
		this.adjust_instructions_close = this.adjust_instructions.querySelector(".mdn-Button-header-close");
		this.adjust_instructions.id = "adjust_instructions";
		this.adjust_instructions.classList.add("mdn-Modal");
		
		
		this.contrast_button = document.querySelector('.main-application #contrast_button');
		this.adjust_button = document.querySelector('.main-application #adjust_button');
		this.buttons = document.querySelectorAll('.main-application a[target="_blank"]');

		this.blank_page_primary = this.blank_page.querySelector('.mdn-Modal-footer .mdn-Button--primary');
		this.blank_page_secondary = this.blank_page.querySelector('.mdn-Modal-footer .mdn-Button--secondary');
		this.adjust_instructions_ok = this.adjust_instructions.querySelector('.mdn-Modal-footer .mdn-Button--secondary');


		this.libras_btn = document.querySelector('.mdn-Icon-libras');
		this.libras_btn.addEventListener("click", e => {
			if (!document.querySelector(".ht-skip")) {
				var ht = new HT({
					token: "7980f66fc04e8a51e244928880939142"
				});
			}
		});
		this.blank_page_primary.addEventListener("click", e => {
			window.open(this.blank_page_primary.dataset.href, "_blank");
			this.blank_page.classList.remove("mdn-is-open");
			document.querySelectorAll(".mdn-Modal").forEach(item=>{
				item.remove();
			});
		});
		this.blank_page_close.addEventListener("click", e => {
			this.blank_page.classList.remove("mdn-is-open");
			document.querySelectorAll(".mdn-Modal").forEach(item=>{
				item.remove();
			});
			this.blank_page_primary.dataset.href = "";
		});
		this.blank_page_secondary.addEventListener("click", e => {
			this.blank_page.classList.remove("mdn-is-open");
			document.querySelectorAll(".mdn-Modal").forEach(item=>{
				item.remove();
			});
			this.blank_page_primary.dataset.href = "";
		});
		this.contrast_button.addEventListener("click", e => {
			if (document.querySelector('html').classList.contains("mdn-Theme--contrast")) {
				window.MondrianVanilla.setTheme(``)
				this.user.data.accessibility.contrast = false
				this.user.update();
			} else {
				window.MondrianVanilla.setTheme(`contrast`)
				this.user.data.accessibility.contrast = true
				this.user.update();
			}
		});

		this.adjust_instructions_close.addEventListener("click", e => {
			this.adjust_instructions.classList.remove("mdn-is-open");
			document.querySelectorAll(".mdn-Modal").forEach(item=>{
				item.remove();
			});
		});
		this.adjust_instructions_ok.addEventListener("click", e => {
			this.adjust_instructions.classList.remove("mdn-is-open");
			document.querySelectorAll(".mdn-Modal").forEach(item=>{
				item.remove();
			});
		});

		this.buttons.forEach(item => {
			item.addEventListener("click", e => {
				e.preventDefault();
				_that.blank_page.classList.add("mdn-is-open");
				_that.blank_page_primary.dataset.href = e.currentTarget.href;
				document.body.append(_that.blank_page);
			});
		});


		try {
			if (this.user.data.accessibility.contrast) {
				window.MondrianVanilla.setTheme(`contrast`)
			}
		} catch (error) {
			console.log(error);
		}
	}
}
window.addEventListener("eHelpFrameworkReady", e => {
	eHelpFramework.widgets.push(new Accessibility())
});
